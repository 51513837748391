<template>
    <div class="nav">

        <div class="wrapper">
            <div class="logo"><img src="@/assets/logo.jpg" alt="#"></div>
            <ul class="navbar-links">

                <li class="link">
                    <RouterLink to="/">Home</RouterLink>
                </li>

                <li class="link dropdown" v-if="this.$route.path == '/'">
                    <a style="display: flex;" @click.prevent>Explore Regions
                        <span class="material-symbols-outlined">arrow_drop_down</span>
                    </a>
                    <ul class="dropdown-menu">
                        <li class="dropdown-item"><a href="#bc">British Columbia</a></li>
                        <li class="dropdown-item"><a href="#alberta">Alberta</a></li>
                        <li class="dropdown-item"><a href="#sas">Saskatchewan</a></li>
                        <li class="dropdown-item"><a href="#manitoba">Manitoba</a></li>
                        <li class="dropdown-item"><a href="#ontario">Ontario</a></li>
                        <li class="dropdown-item"><a href="#quebec">Quebec</a></li>
                        <li class="dropdown-item"><a href="#brunswick">New Brunswick</a></li>
                        <li class="dropdown-item"><a href="#scotia">Nova Scotia</a></li>
                        <li class="dropdown-item"><a href="#edward">Prince Edward Island</a></li>
                        <li class="dropdown-item"><a href="#labrador">Newfoundland and Labrador</a></li>
                    </ul>

                </li>
                <li class="link" v-else>
                    <router-link to="/">Explore Regions</router-link>
                </li>

                <li class="link">
                    <a href="#food" v-if="this.$route.path == '/'">Cuisine & Restaurants</a>
                    <router-link to="/" v-else>Cuisine & Restaurants</router-link>
                </li>

                <li class="link">
                    <router-link to="/hotels">Hotels</router-link>
                </li>

                <li class="link">
                    <RouterLink id="" to="/travel-info">Travel Information</RouterLink>
                </li>

                <li class="link dropdown">
                    <a style="display: flex;" @click.prevent>Information
                        <span class="material-symbols-outlined">arrow_drop_down</span>
                    </a>
                    <ul class="dropdown-menu">
                        <li class="dropdown-item"><router-link to="/contact">Contact US</router-link></li>
                        <li class="dropdown-item"><router-link to="/privacy">Privacy Policy</router-link></li>
                        <li class="dropdown-item"><router-link to="/terms">Terms of Service</router-link></li>
                        <li class="dropdown-item"><router-link to="/cookie-policy">Cookie Policy</router-link></li>
                    </ul>
                </li>
            </ul>

            <!-- MOBILE MENU Locale-->
                <div class="mobile-menu" @click="showMenu">
                    <img src="@/assets/menu.png" alt="">
                </div>
            <!-- END MOBILE MENU Locale -->
        </div>
    </div>

    <aside v-show="show" ref="sidemenu">
        <div class="sidemenu">
            <span class="material-symbols-outlined" @click.prevent="show = false">close</span>
            <ul>
                <li>
                    <RouterLink to="/">Home</RouterLink>
                </li>
                <div class="indent" v-if="this.$route.path == '/'">
                    <h1>Explore Regions</h1>
                    <li><a href="#bc">British Columbia</a></li>
                    <li><a href="#alberta">Alberta</a></li>
                    <li><a href="#sas">Saskatchewan</a></li>
                    <li><a href="#manitoba">Manitoba</a></li>
                    <li><a href="#ontario">Ontario</a></li>
                    <li><a href="#quebec">Quebec</a></li>
                    <li><a href="#brunswick">New Brunswick</a></li>
                    <li><a href="#scotia">Nova Scotia</a></li>
                    <li><a href="#edward">Prince Edward Island</a></li>
                    <li><a href="#labrador">Newfoundland and Labrador</a></li>
                </div>
                <li v-else>
                    <router-link to="/">Explore Regions</router-link>
                </li>

                <li>
                    <a href="#food" v-if="this.$route.path == '/'">Cuisine & Restaurants</a>
                    <router-link to="/" v-else>Cuisine & Restaurants</router-link>
                </li>
                <li><router-link to="/hotels">Hotels</router-link></li>
                <li>
                    <router-link to="/travel-info">Travel Information</router-link>
                </li>
                <div class="indent">
                    <h1>Information</h1>
                    <li>
                        <router-link to="/privacy">Privacy Polic</router-link>
                    </li>
                    <li>
                        <router-link to="terms">Terms of Service</router-link>
                    </li>
                    <li>
                        <router-link to="cookie-policy">Cookie Policy</router-link>
                    </li>
                </div>
            </ul>
        </div>
    </aside>
</template>

<script>

export default {
    data() {
        return {
            show: false
        }
    },
    mounted() {
    },
    methods: {
        showMenu() {
            this.show = !this.show;
        }
    }
}
</script>

<style scoped>
* {
    list-style: none;
}

/* SIDE MENU */
aside {
    text-align: left;
}

aside .sidemenu * {
    z-index: 100 !important;
}

aside .sidemenu {
    overflow-y: scroll;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.748);
    height: 100%;
    position: fixed;
    width: 30%;
    color: var(--white);
}

/* close Logo */
aside .sidemenu span {
    position: absolute;
    right: 1rem;
    top: 1rem;
    font-size: 1.4rem;
}

/* end close logo */

/* Unordered list styling */
aside .sidemenu ul {
    display: flex;
    flex-direction: column;
    margin: 3rem;
    justify-content: flex-start;
    gap: 1rem;
}

aside .sidemenu a {
    font-size: 1.3rem;
    font-weight: 400;
    letter-spacing: 1px;
    color: var(--white);
}

aside .sidemenu h1 {
    font-size: 1.3rem;
    font-weight: 400;
    letter-spacing: 1px;
    color: var(--white);
}

/* end Unordered list styling*/

/* Indentation List */
aside .sidemenu .indent {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    padding: 0.4em 0;
}

aside .sidemenu ul .indent li {
    margin-left: 1rem;
}

aside .sidemenu ul .indent li h1 {
    font-weight: 200;
}

aside .sidemenu ul .indent li a {
    font-size: 1rem;
    font-weight: 300;
    letter-spacing: 1px;
    color: var(--white);
}

/* end Indentaion List */

/* Moible Menu Logo Snadwich Locale Selector*/
.mobile-menu {
    height: 30px;
    display: none;
}

.mobile-menu span {
    font-size: 2rem;
}

/* end SIDE MENU */
.nav .wrapper {
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.nav .wrapper .logo {
    width: 100px;
    cursor: pointer;
}

.nav .wrapper .logo img {
    border-radius: 1rem;
}

.navbar-links {
    list-style: none;
    display: flex;
    gap: 1rem;
}

.navbar-links .link {
    position: relative;
}

.navbar-links .link a {
    text-decoration: none;
    padding: 0.5rem 1rem;
    display: block;
}

.navbar-links .link a:hover {
    color: rgb(73, 255, 137);
}

.dropdown-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: var(--white);
    padding: 0.5rem 0;
}

.dropdown-item a {
    padding: 0.5rem 1rem;
    display: block;
}

.dropdown-item a:hover {
    background-color: #e3e3e3;
}

.navbar-links .link.dropdown:hover .dropdown-menu {
    display: block;
}

@media screen and (max-width: 900px) {
    .nav .wrapper {
        justify-content: space-between;
        padding: 0 3em;
    }

    .flex {
        display: flex;
        gap: 2em;
    }

    .mobile-menu {
        display: block;
    }

    .navbar-links {
        display: none;
    }
}

@media screen and (max-width: 700px) {
    aside .sidemenu {
        width: 45%;
    }
}

@media screen and (max-width: 500px) {
    aside .sidemenu {
        width: 45%;
    }

    aside .sidemenu a {
        font-size: 1rem;
        font-weight: 300;
        letter-spacing: 1px;
    }

    aside .sidemenu .indent a {
        font-size: 0.7rem;
        font-weight: 400;
    }

    .nav .wrapper .logo {
        width: 50px;
    }

    .mobile-menu {
        height: 20px;
    }

    .locale {
        flex-direction: row;
        align-items: center;
        gap: 1em;
    }

    .locale #locale {
        font-size: 0.7rem;
    }


}

@media screen and (max-width: 380px) {
    aside .sidemenu {
        width: 65%;
    }

}
</style>