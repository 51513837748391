<template>
    <div class="content container">
        <div class="header">
            <h1>Food Lovers Culinary Journey</h1>
            <p>Canadian cuisine reflects the country's diverse landscape and cultural heritage. From iconic dishes to
                innovative restaurant menus, here’s an in depth exploration of 20 must try Canadian dishes and 10
                standout restaurants where you can savor these delights</p>
        </div>

        <div class="flex">
            <div class="card">
                <h1>1. Poutine</h1>
                <div class="image"><img src="@/assets/Poutine.webp" alt=""></div>

                <p>Poutine, originating in Quebec, is a quintessential Canadian comfort food consisting of fresh cut
                    fries topped with squeaky cheese curds and smothered in rich gravy. This humble dish has evolved
                    into a gourmet delight with numerous variations, such as lobster poutine in Nova Scotia and pulled
                    pork poutine in Toronto. The secret to a great poutine lies in the cheese curds that must be fresh
                    enough to squeak when bitten into, indicating their quality. Whether enjoyed at a food truck, a
                    greasy spoon, or a high end restaurant, poutine remains a beloved staple across Canada </p>
            </div>

            <div class="card">
                <h1>2. Butter Tarts</h1>
                <div class="image"><img src="@/assets/ButterTarts.jpg" alt=""></div>

                <p>Butter tarts are a classic Canadian dessert featuring a flaky pastry shell filled with a gooey
                    mixture of butter, sugar, and eggs. Often enhanced with raisins or pecans, these tarts are a sweet
                    treat enjoyed throughout the country. Each region may have its slight variation, but the essential
                    characteristics remain the same  a crisp, buttery crust and a rich, caramel like filling. Butter
                    tarts are a testament to Canada's love for sweet, home baked goods and are a must try for anyone
                    with a sweet tooth </p>
            </div>

            <div class="card">
                <h1>3. Nanaimo Bars</h1>
                <div class="image"><img src="@/assets/NanaimoBars.jpg" alt=""></div>

                <p>Named after the city of Nanaimo in British Columbia, Nanaimo bars are a no bake dessert made of three
                    layers  a crumbly base of chocolate, nuts, and coconut, a custard flavored middle layer, and a
                    chocolate ganache topping. These bars are a popular treat at holidays and gatherings, known for
                    their rich and decadent flavor. Nanaimo bars have become a symbol of Canadian culinary creativity
                    and are enjoyed across the country and beyond </p>
            </div>

            <div class="card">
                <h1>4. Tourtière</h1>
                <div class="image"><img src="@/assets/Tourtière.jpeg" alt=""></div>

                <p>Tourtière is a traditional Quebecois meat pie usually filled with a mixture of pork, veal, and beef,
                    seasoned with cloves, cinnamon, and allspice. This hearty pie is particularly popular during the
                    Christmas season, but it's enjoyed year round as well. Each family often has its own recipe, passed
                    down through generations, making tourtière a dish steeped in cultural heritage. It's typically
                    served with a side of pickled beets or ketchup, adding a tangy contrast to the savory filling </p>
            </div>

            <div class="card">
                <h1>5. Caesar Cocktail</h1>
                <div class="image"><img src="@/assets/Caesar-Cocktail.jpg" alt=""></div>

                <p>The Caesar cocktail, invented in Calgary in 1969, is a staple at Canadian brunches and celebrations.
                    Made with clamato juice (a blend of tomato and clam juices), vodka, hot sauce, and Worcestershire
                    sauce, it's typically garnished with celery and a lime wedge. Variations often include extravagant
                    garnishes like bacon, shrimp, or even mini burgers. The Caesar's unique combination of flavors has
                    earned it a special place in Canadian culture, being enjoyed over 350 million times annually </p>
            </div>

            <div class="card">
                <h1>6. Maple Syrup</h1>
                <div class="image"><img src="@/assets/MapleSyrup.webp" alt=""></div>

                <p>Maple syrup is a quintessential Canadian ingredient, used in a variety of dishes from breakfast to
                    dessert. Canada is the world's largest producer of maple syrup, with Quebec accounting for over 70%
                    of the global supply. This sweet, amber syrup is made by boiling down the sap of sugar maple trees,
                    resulting in a rich, caramelized flavor. It's drizzled over pancakes, used as a glaze for meats, and
                    even incorporated into cocktails, highlighting the versatility and beloved status of this natural
                    sweetener </p>
            </div>

            <div class="card">
                <h1>7. Lobster Rolls</h1>
                <div class="image"><img src="@/assets/LobsterRolls.jpg" alt=""></div>

                <p>Lobster rolls, especially popular in Atlantic Canada, feature fresh lobster meat mixed with
                    mayonnaise and seasonings, served in a buttery, toasted bun. This dish showcases the region's rich
                    maritime heritage and access to fresh seafood. Lobster rolls are often enjoyed during the summer
                    months, providing a delicious taste of the sea. Variations can include additions like celery, lemon
                    juice, or herbs, but the focus remains on the succulent lobster meat </p>
            </div>

            <div class="card">
                <h1>8. BeaverTails</h1>
                <div class="image"><img src="@/assets/BeaverTails.jpg" alt=""></div>

                <p>BeaverTails are a unique Canadian treat, consisting of fried dough shaped like a beaver's tail and
                    topped with a variety of sweet toppings such as cinnamon sugar, Nutella, and bananas. Originally
                    from Ontario, BeaverTails have become popular across Canada, especially at fairs, festivals, and
                    tourist destinations. These pastries are a delightful indulgence, offering a taste of Canada's
                    playful and creative approach to desserts </p>
            </div>

            <div class="card">
                <h1>9. Montreal Style Bagels</h1>
                <div class="image"><img src="@/assets/Bagels.jpg" alt=""></div>

                <p>Montreal style bagels are smaller, denser, and sweeter than their New York counterparts, boiled in
                    honey sweetened water before being baked in a wood fired oven. They come in varieties like sesame
                    and poppy seed and are typically enjoyed with cream cheese or smoked salmon. St Viateur and
                    Fairmount Bagel are two iconic bagel shops in Montreal, each boasting a loyal following. These
                    bagels are a must try for their unique texture and flavor, reflecting the city's rich culinary
                    tradition </p>
            </div>

            <div class="card">
                <h1>10. Ginger Beef</h1>
                <div class="image"><img src="@/assets/GingerBeef.jpg" alt=""></div>

                <p>Ginger beef is a Calgary specialty, featuring strips of beef that are battered, deep fried, and
                    coated in a sweet and spicy sauce made with ginger, soy sauce, and sugar. This dish is a fusion of
                    Western and Chinese cuisines and has become a popular takeout option in Western Canada. The crispy
                    texture of the beef combined with the tangy sauce makes for a delicious and satisfying meal,
                    highlighting the innovative spirit of Canadian cuisine </p>
            </div>

            <div class="card">
                <h1>11. Fèves au Lard</h1>
                <div class="image"><img src="@/assets/FèvesauLard.jpg" alt=""></div>

                <p>Fèves au Lard, or baked beans, is a traditional Quebecois dish made with beans, bacon, and flavored
                    with maple syrup and molasses. This hearty dish is slow cooked to develop a rich, savory sweet
                    flavor and is often served at sugar shacks during maple syrup season. Fèves au Lard reflects the
                    rustic, comforting nature of Quebecois cuisine and is a favorite at family gatherings and community
                    events </p>
            </div>

            <div class="card">
                <h1>12. Tarte au Sucre</h1>
                <div class="image"><img src="@/assets/TarteauSucre.jpg" alt=""></div>

                <p>Tarte au Sucre, or sugar pie, is a classic French Canadian dessert made with a filling of brown
                    sugar, cream, and butter, baked in a flaky pastry crust. The filling caramelizes as it bakes,
                    creating a rich and indulgent treat that is often enjoyed during the holidays. This pie is a
                    testament to Quebec's culinary heritage, offering a sweet taste of tradition </p>
            </div>

            <div class="card">
                <h1>13. Peameal Bacon</h1>
                <div class="image"><img src="@/assets/PeamealBacon.jpg" alt=""></div>

                <p>Peameal bacon, often referred to as "Canadian bacon," is pork loin rolled in cornmeal, creating a
                    distinctive texture and flavor. It's typically fried and served in sandwiches or as part of a hearty
                    breakfast. This dish is a staple in Ontario, especially in Toronto's St. Lawrence Market, where
                    vendors like Carousel Bakery are famous for their peameal bacon sandwiches </p>
            </div>

            <div class="card">
                <h1>14. Bannock</h1>
                <div class="image"><img src="@/assets/Bannock.jpg" alt=""></div>

                <p>Bannock is a type of flatbread with roots in Indigenous cuisine, made from flour, water, and
                    sometimes fat, and cooked over an open flame or fried. This versatile bread is enjoyed across Canada
                    and can be served sweet or savory. Bannock is a symbol of resilience and tradition, reflecting the
                    culinary heritage of Indigenous peoples </p>
            </div>

            <div class="card">
                <h1>15. Split Pea Soup</h1>
                <div class="image"><img src="@/assets/SplitPeaSoup.webp" alt=""></div>

                <p>Split pea soup is a hearty and comforting dish made with dried split peas, ham hock, and vegetables.
                    This soup is particularly popular in Quebec, where it is enjoyed as a warming meal during the cold
                    winter months. The thick, creamy texture and smoky flavor from the ham make it a satisfying and
                    nourishing option </p>
            </div>

            <div class="card">
                <h1>16. Macaroni and Cheese</h1>
                <div class="image"><img src="@/assets/MacaroniCheese.jpg" alt=""></div>

                <p>Macaroni and cheese, affectionately known as "mac and cheese," is a beloved comfort food in Canada,
                    often made with Canadian cheddar for a rich, creamy texture. This dish is a favorite among children
                    and adults alike, embodying the country’s love for dairy products. Homemade versions often include
                    breadcrumbs or bacon for added flavor and texture </p>
            </div>

            <div class="card">
                <h1>17. Baked Beans</h1>
                <div class="image"><img src="@/assets/BakedBeans.jpg" alt=""></div>

                <p>Baked beans are a traditional Canadian dish made with black beans, lard, molasses, and maple syrup,
                    slow cooked to develop a deep, savory sweet flavor. This dish is often enjoyed at breakfast
                    alongside eggs and toast or as a hearty side at barbecues. Baked beans are a nod to Canada’s
                    colonial history and the influence of European settlers </p>
            </div>

            <div class="card">
                <h1>18. Puffed Wheat Squares</h1>
                <div class="image"><img src="@/assets/PuffedWheatSquares.jpg" alt=""></div>

                <p>Puffed wheat squares are a classic Canadian treat made with puffed wheat cereal, butter, and a sticky
                    syrup, often flavored with cocoa or molasses. These chewy, sweet bars are a staple at bake sales and
                    family gatherings, beloved for their simplicity and nostalgic appeal </p>
            </div>

            <div class="card">
                <h1>19. Canadian Date Bars</h1>
                <div class="image"><img src="@/assets/DateBars.jpg" alt=""></div>

                <p>Canadian date bars are a delicious dessert with a base of dates and oats, topped with a sweet
                    crumble. These bars are popular for their rich, caramel like flavor and chewy texture, making them a
                    perfect treat for any occasion. The combination of dates and oats reflects Canada’s appreciation for
                    wholesome, natural ingredients </p>
            </div>

            <div class="card">
                <h1>20. Vegetable Soup with Barley</h1>
                <div class="image"><img src="@/assets/Barley.jpg" alt=""></div>

                <p>Vegetable soup with barley is a hearty, nutritious dish that showcases Canada’s abundant produce and
                    grains. Made with a variety of vegetables and pearl barley, this soup is a comforting and filling
                    meal, perfect for the colder months. The barley adds a chewy texture and nutty flavor, while the
                    vegetables contribute a range of nutrients and colors. This soup is a staple in many Canadian
                    households, reflecting the country’s emphasis on wholesome, home cooked meals </p>
            </div>

            <h1>Must Visit Restaurants</h1>

            <div class="card">
                <h1>1. Joe Beef (Montreal, Quebec)</h1>
                <p>Joe Beef, located in Montreal’s Little Burgundy neighborhood, is renowned for its indulgent and
                    innovative takes on traditional Quebecois cuisine. The restaurant’s menu changes frequently,
                    showcasing seasonal ingredients and bold flavors. Dishes such as foie gras, seafood platters, and
                    rich pasta creations highlight the chefs' creativity and commitment to quality. Joe Beef’s eclectic
                    decor and lively atmosphere make it a must visit destination for food enthusiasts looking to
                    experience the best of Montreal’s culinary scene</p>
            </div>

            <div class="card">
                <h1>2. The Fogo Island Inn (Newfoundland and Labrador)</h1>
                <p>The Fogo Island Inn offers a unique dining experience that emphasizes local and seasonal ingredients.
                    Situated on a remote island off the coast of Newfoundland, the inn’s restaurant serves dishes
                    inspired by traditional Newfoundland cuisine, using ingredients such as wild berries, North Atlantic
                    seafood, and locally raised meats. The menu changes daily, reflecting the availability of fresh,
                    local produce. The dining experience at the Fogo Island Inn is enhanced by its stunning ocean views
                    and the opportunity to learn about the island’s rich cultural heritage</p>
            </div>

            <div class="card">
                <h1>3. Alo (Toronto, Ontario)</h1>
                <p>Alo is a fine dining restaurant in Toronto known for its contemporary French cuisine and exceptional
                    service. The restaurant offers a tasting menu that changes seasonally, featuring dishes that
                    highlight the finest ingredients and culinary techniques. Guests can enjoy a multi course meal that
                    includes delicacies such as foie gras, fresh seafood, and expertly prepared meats. Alo’s elegant
                    setting and attentive staff make it a top choice for special occasions and gourmet dining in
                    Toronto</p>
            </div>

            <div class="card">
                <h1>4. Raymonds (St. John’s, Newfoundland)</h1>
                <p>Raymonds is celebrated for its use of fresh, local ingredients and its dedication to showcasing the
                    flavors of Newfoundland. The restaurant’s menu features a variety of dishes made with ingredients
                    sourced from local farmers, fishers, and foragers. Highlights include fresh Atlantic seafood, wild
                    game, and seasonal vegetables. Raymonds’ sophisticated yet welcoming atmosphere, combined with its
                    commitment to sustainability and local sourcing, makes it a standout dining destination in St.
                    John’s</p>
            </div>

            <div class="card">
                <h1>5. Toqué! (Montreal, Quebec)</h1>
                <p>Toqué! is a pioneer in the farm to table movement in Canada, offering a menu that highlights Quebec’s
                    agricultural bounty. The restaurant’s dishes are crafted with fresh, seasonal ingredients, often
                    sourced directly from local farms. The menu includes a variety of innovative dishes that showcase
                    the flavors and textures of Quebec’s finest produce. Toqué!’s dedication to sustainability and
                    quality has earned it a reputation as one of Montreal’s premier dining establishments</p>
            </div>

            <div class="card">
                <h1>6. Hawksworth (Vancouver, British Columbia)</h1>
                <p>Hawksworth, located in Vancouver’s Rosewood Hotel Georgia, is known for its modern Canadian cuisine
                    and elegant dining experience. The restaurant’s menu features a blend of local and international
                    flavors, with dishes such as Pacific salmon, roasted duck, and creative vegetarian options.
                    Hawksworth’s stylish decor and commitment to culinary excellence make it a top choice for fine
                    dining in Vancouver</p>
            </div>

            <div class="card">
                <h1>7. Langdon Hall (Cambridge, Ontario)</h1>
                <p>Langdon Hall offers a refined dining experience in a historic country estate setting. The
                    restaurant’s menu focuses on regional ingredients and classical cooking techniques, with dishes such
                    as roasted lamb, garden fresh salads, and decadent desserts. Guests can enjoy a meal in the elegant
                    dining room or on the scenic terrace, surrounded by the estate’s beautiful gardens and woodlands.
                    Langdon Hall’s dedication to quality and its serene atmosphere make it a perfect destination for a
                    memorable dining experience</p>
            </div>

            <div class="card">
                <h1>8. The Salmon House (West Vancouver, British Columbia)</h1>
                <p>The Salmon House is renowned for its seafood dishes and stunning views of Vancouver’s skyline and
                    harbor. The restaurant’s menu features a variety of Pacific Northwest specialties, including
                    cedar planked salmon, Dungeness crab, and fresh oysters. The Salmon House’s commitment to sourcing
                    sustainable seafood and its elegant, rustic decor make it a standout dining destination on the west
                    coast</p>
            </div>

            <div class="card">
                <h1>9. Chives Canadian Bistro (Halifax, Nova Scotia)</h1>
                <p>Chives Canadian Bistro specializes in Atlantic Canadian cuisine, using fresh, local ingredients to
                    create flavorful and inventive dishes. The menu includes items such as seafood chowder, lobster
                    rolls, and farm fresh salads. Chives’ cozy, welcoming atmosphere and dedication to showcasing the
                    best of Nova Scotia’s culinary offerings make it a favorite among locals and visitors alike</p>
            </div>

            <div class="card">
                <h1>10. The Harvest Room (Edmonton, Alberta)</h1>
                <p>The Harvest Room, located in the Fairmont Hotel Macdonald, offers a contemporary Canadian dining
                    experience with a focus on regional ingredients. The menu features dishes such as Alberta beef,
                    bison, and locally sourced vegetables, all prepared with a modern twist. The restaurant’s elegant
                    setting and panoramic views of the North Saskatchewan River Valley provide the perfect backdrop for
                    a sophisticated dining experience</p>
            </div>

        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.content {
    padding-bottom: 3rem;
}

.content .header {
    margin-top: 4rem;
}

.content .header h1 {
    font-size: 2.3;
    letter-spacing: 2px;
    font-weight: 300;
}

.content .header p {
    margin: 3rem auto 0 auto;
    width: 55%;
}

.content .flex {
    width: 70%;
    margin: 2rem auto;
    display: flex;
    flex-direction: column;
    gap: 2em;
}

.content .flex .card {
    display: flex;
    flex-direction: column;
    gap: 2em;
}

.content .flex .card .image {
    width: 600px;
    height: 400px;
    margin: 0 auto;
}

.content .flex .card .image img {
    background-size: cover;
    border-radius: 1rem;
}

.content .flex .card h1 {
    font-size: 2.3;
    letter-spacing: 2px;
    font-weight: 300;
}

.content .flex .card p {
    width: 80%;
    letter-spacing: 2px;
    font-weight: 300;
    margin: 0 auto;
}

@media (max-width: 1000px) 
{
    .content .flex .card .image{
        width: 60%;
        height: auto;
    }
} 

@media (max-width: 800px) 
{

} 

@media (max-width: 500px) 
{

} 

@media (max-width: 360px) 
{

}
</style>