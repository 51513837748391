<template>
    <div class="container">
        <div class="heading">
            <h1>{{ $t('Terms of Service') }}</h1>
        </div>

        <div class="flex">
            <h2>{{ $t('privacy-flex-1') }}</h2>
            <p>{{ $t('privacy-flex-2') }}</p>
            <p>{{ $t('privacy-flex-3') }}</p>
            <p>{{ $t('privacy-flex-4') }}</p>
            <p>{{ $t('privacy-flex-5') }}</p>

            <h2>{{ $t('privacy-flex-6') }}</h2>
            <p>{{ $t('privacy-flex-7') }}</p>
            <p>{{ $t('privacy-flex-8') }}</p>
            <ul>
                <li>{{ $t('privacy-flex-9') }}</li>
                <li>{{ $t('privacy-flex-10') }}</li>
            </ul>

            <h2>{{ $t('privacy-flex-11') }}</h2>
            <p>{{ $t('privacy-flex-12') }}</p>
            <ul>
                <li>{{ $t('privacy-flex-13') }}</li>
                <li>{{ $t('privacy-flex-14') }}</li>
                <li>{{ $t('privacy-flex-15') }}</li>
                <li>{{ $t('privacy-flex-16') }}</li>
                <li>{{ $t('privacy-flex-17') }}</li>
            </ul>
            <p>{{ $t('privacy-flex-18') }}</p>
            <p>{{ $t('privacy-flex-19') }}</p>
            <p>{{ $t('privacy-flex-20') }}</p>
            <p>{{ $t('privacy-flex-21') }}</p>
            <p>{{ $t('privacy-flex-22') }}</p>
            <ul>
                <li>{{ $t('privacy-flex-23') }}</li>
                <li>{{ $t('privacy-flex-24') }}</li>
                <li>{{ $t('privacy-flex-25') }}</li>
                <li>{{ $t('privacy-flex-26') }}</li>
                <li>{{ $t('privacy-flex-27') }}</li>
            </ul>
            <p>{{ $t('privacy-flex-28') }}</p>
            <p>{{ $t('privacy-flex-29') }}</p>

            <h2>{{ $t('privacy-flex-30') }}</h2>
            <p>{{ $t('privacy-flex-31') }}</p>
            <ul>
                <li>{{ $t('privacy-flex-32') }}</li>
                <li>{{ $t('privacy-flex-33') }}</li>
                <li>{{ $t('privacy-flex-34') }}</li>
                <li>{{ $t('privacy-flex-35') }}</li>
                <li>{{ $t('privacy-flex-36') }}</li>
                <li>{{ $t('privacy-flex-37') }}</li>
                <li>{{ $t('privacy-flex-38') }}</li>
                <li>{{ $t('privacy-flex-39') }}</li>
                <li>{{ $t('privacy-flex-40') }}</li>
                <li>{{ $t('privacy-flex-41') }}</li>
                <li>{{ $t('privacy-flex-42') }}</li>
                <li>{{ $t('privacy-flex-43') }}</li>
                <li>{{ $t('privacy-flex-44') }}</li>
                <li>{{ $t('privacy-flex-45') }}</li>
            </ul>
            <p>{{ $t('privacy-flex-46') }}</p>
            <p>{{ $t('privacy-flex-47') }}</p>

            <h2>{{ $t('privacy-flex-48') }}</h2>
            <p>{{ $t('privacy-flex-49') }}</p>
            <p>{{ $t('privacy-flex-50') }}</p>
            <p>{{ $t('privacy-flex-51') }}</p>
            <p>{{ $t('privacy-flex-52') }}</p>
            <p>{{ $t('privacy-flex-53') }}</p>

            <h2>{{ $t('privacy-flex-54') }}</h2>
            <p>{{ $t('privacy-flex-55') }}</p>
            <p>{{ $t('privacy-flex-56') }}</p>
            <p>{{ $t('privacy-flex-57') }}</p>
            <p>{{ $t('privacy-flex-58') }}</p>
            <p>{{ $t('privacy-flex-59') }}</p>

            <h2>{{ $t('privacy-flex-60') }}</h2>
            <p>{{ $t('privacy-flex-61') }}</p>
            <ul>
                <li>{{ $t('privacy-flex-62') }}</li>
                <li>{{ $t('privacy-flex-63') }}</li>
                <li>{{ $t('privacy-flex-64') }}</li>
            </ul>
            <p>{{ $t('privacy-flex-65') }}</p>
            <p>{{ $t('privacy-flex-66') }}</p>
            <p>{{ $t('privacy-flex-67') }}</p>

            <h2>{{ $t('privacy-flex-68') }}</h2>
            <p>{{ $t('privacy-flex-69') }}</p>
            <p>{{ $t('privacy-flex-70') }}</p>
            <p>{{ $t('privacy-flex-71') }}</p>
            <p>{{ $t('privacy-flex-72') }}</p>
            <p>{{ $t('privacy-flex-73') }}</p>
            <p>{{ $t('privacy-flex-74') }}</p>
            <p>{{ $t('privacy-flex-75') }}</p>

            <h2>{{ $t('privacy-flex-76') }}</h2>
            <p>{{ $t('privacy-flex-77') }}</p>
            <p>{{ $t('privacy-flex-78') }}</p>
            <p>{{ $t('privacy-flex-79') }}</p>
            <ul>
                <li>{{ $t('privacy-flex-80') }}</li>
                <li>{{ $t('privacy-flex-81') }}</li>
                <li>{{ $t('privacy-flex-82') }}</li>
                <li>{{ $t('privacy-flex-83') }}</li>
                <li>{{ $t('privacy-flex-84') }}</li>
                <li>{{ $t('privacy-flex-85') }}</li>
                <li>{{ $t('privacy-flex-86') }}</li>
                <li>{{ $t('privacy-flex-87') }}</li>
                <li>{{ $t('privacy-flex-88') }}</li>
                <li>{{ $t('privacy-flex-89') }}</li>
                <li>{{ $t('privacy-flex-90') }}</li>
                <li>{{ $t('privacy-flex-91') }}</li>
                <li>{{ $t('privacy-flex-92') }}</li>
                <li>{{ $t('privacy-flex-93') }}</li>
                <li>{{ $t('privacy-flex-94') }}</li>
                <li>{{ $t('privacy-flex-95') }}</li>
                <li>{{ $t('privacy-flex-96') }}</li>
                <li>{{ $t('privacy-flex-97') }}</li>
            </ul>

            <h2>{{ $t('privacy-flex-98') }}</h2>
            <p>{{ $t('privacy-flex-99') }}</p>
            <ul>
                <li>{{ $t('privacy-flex-100') }}</li>
                <li>{{ $t('privacy-flex-101') }}</li>
                <li>{{ $t('privacy-flex-102') }}</li>
                <li>{{ $t('privacy-flex-103') }}</li>
            </ul>
            <p>{{ $t('privacy-flex-104') }}</p>
            <p>{{ $t('privacy-flex-105') }}</p>

            <h2>{{ $t('privacy-flex-106') }}</h2>
            <p>{{ $t('privacy-flex-107') }}</p>
            <ul>
                <li>{{ $t('privacy-flex-108') }}</li>
                <li>{{ $t('privacy-flex-109') }}</li>
                <li>{{ $t('privacy-flex-110') }}</li>
                <li>{{ $t('privacy-flex-111') }}</li>
            </ul>
            <p>{{ $t('privacy-flex-112') }}</p>
            <ul>
                <li>{{ $t('privacy-flex-113') }}</li>
                <li>{{ $t('privacy-flex-114') }}</li>
            </ul>
            <p>{{ $t('privacy-flex-115') }}</p>
            <p>{{ $t('privacy-flex-116') }}</p>
            <p>{{ $t('privacy-flex-117') }}</p>
            <p>{{ $t('privacy-flex-118') }}</p>
            <p>{{ $t('privacy-flex-119') }}</p>
            <p>{{ $t('privacy-flex-120') }}</p>

            <h2>{{ $t('privacy-flex-121') }}</h2>
            <p>{{ $t('privacy-flex-122') }}</p>
            <ul>
                <li>{{ $t('privacy-flex-123') }}</li>
                <li>{{ $t('privacy-flex-124') }}</li>
                <li>{{ $t('privacy-flex-125') }}</li>
                <li>{{ $t('privacy-flex-126') }}</li>
                <li>{{ $t('privacy-flex-127') }}</li>
                <li>{{ $t('privacy-flex-128') }}</li>
                <li>{{ $t('privacy-flex-129') }}</li>
            </ul>
            <p>{{ $t('privacy-flex-130') }}</p>

            <h2>{{ $t('privacy-flex-131') }}</h2>
            <p>{{ $t('privacy-flex-132') }}</p>
            <p>{{ $t('privacy-flex-133') }}</p>
            <p>{{ $t('privacy-flex-134') }}</p>

            <h2>{{ $t('privacy-flex-135') }}</h2>
            <p>{{ $t('privacy-flex-136') }}</p>
            <p>{{ $t('privacy-flex-137') }}</p>

            <h2>{{ $t('privacy-flex-138') }}</h2>
            <p>{{ $t('privacy-flex-139') }}</p>
            <p>{{ $t('privacy-flex-140') }}</p>

            <h2>{{ $t('privacy-flex-141') }}</h2>
            <p>{{ $t('privacy-flex-142') }}</p>
            <p>{{ $t('privacy-flex-143') }}</p>

            <h2>{{ $t('privacy-flex-144') }}</h2>
            <p>{{ $t('privacy-flex-145') }}</p>

            <h2>{{ $t('privacy-flex-146') }}</h2>
            <p>{{ $t('privacy-flex-147') }}</p>
            <p>{{ $t('privacy-flex-148') }}</p>

            <h2>{{ $t('privacy-flex-149') }}</h2>
            <p>{{ $t('privacy-flex-150') }}</p>
            <p>{{ $t('privacy-flex-151') }}</p>
            <p>{{ $t('privacy-flex-152') }}</p>
            <p>{{ $t('privacy-flex-153') }}</p>

            <h2>{{ $t('privacy-flex-154') }}</h2>
            <p>{{ $t('privacy-flex-155') }}</p>
            <p>{{ $t('privacy-flex-156') }}</p>
            <p>{{ $t('privacy-flex-157') }}</p>
            <p>{{ $t('privacy-flex-158') }}</p>
            <ul>
                <li>{{ $t('privacy-flex-159') }}</li>
                <li>{{ $t('privacy-flex-160') }}</li>
                <li>{{ $t('privacy-flex-161') }}</li>
                <li>{{ $t('privacy-flex-162') }}</li>
            </ul>
        </div>

    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
h1 {
    font-size: 2rem;
    letter-spacing: 2px;
    font-weight: 300;
}

h2 {
    font-size: 1.3rem;
}

p {
    font-size: 1rem;
    font-weight: 300;
    letter-spacing: 1px;
    margin-top: 1em;
}

.heading {
    /* border: 1px solid black; */
    padding: 3em;
}

.heading h1 {
    font-size: 2.5rem;
    font-weight: 400;
}

.flex {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    text-align: left;
    padding: 2rem 2rem;
}

@media screen and (max-width: 500px) {
    h1 {
        font-size: 1.8rem;
    }

    p {
        font-size: 1rem;
    }

    .heading h1 {
        font-size: 2rem;
    }
}
</style>