<template>
    <div class="content container">
        <div class="header">
            <h1>Exploring Canada's Diverse Regions</h1>
            <p>Canada, a land of vast landscapes and rich cultures, offers a wide array of experiences across its ten
                provinces and three territories. Each region boasts unique attractions, from stunning natural beauty to
                vibrant cities, historical sites, and cultural treasures. This guide will take you through the
                highlights of each region, helping you plan a memorable journey through this incredible country</p>
        </div>

        <div class="grid">
            <div class="card" id="bc">
                <div class="heading">
                    <h1>
                        1. British Columbia
                    </h1>
                    <div class="image"><img src="@/assets/bc.webp" alt="British Columbia scenery"></div>
                </div>


                <h1>Introduction</h1>

                <p>
                    British Columbia (BC) is known for its stunning coastal scenery, towering mountains, and vibrant
                    cities. It's a haven for outdoor enthusiasts and urban explorers alike.
                </p>

                <h1>Key Attractions</h1>
                <ul>
                    <li>
                        <p><strong>Vancouver</strong> This bustling city offers a mix of modern urban life and access
                            to nature. Explore Stanley Park, visit the Vancouver Aquarium, and enjoy the diverse
                            culinary scene</p>
                    </li>
                    <li>
                        <p><strong>Victoria</strong> The capital of BC, located on Vancouver Island, is famous for its
                            British colonial history, beautiful gardens, and the iconic Butchart Gardens</p>
                    </li>
                    <li>
                        <p><strong>Whistler</strong> Known for world class skiing and outdoor activities, Whistler is a
                            year round destination for adventure seekers</p>
                    </li>
                    <li>
                        <p><strong>Okanagan Valley</strong> This region is renowned for its wineries, orchards, and the
                            beautiful Okanagan Lake, perfect for a relaxing getaway</p>
                    </li>
                </ul>

                <h1 >Activities</h1>
                <ul>
                    <li>
                        <p>Skiing and snowboarding in Whistler</p>
                    </li>
                    <li>
                        <p>Hiking in the Pacific Rim National Park</p>
                    </li>
                    <li>
                        <p>Wine tasting in the Okanagan Valley</p>
                    </li>
                    <li>
                        <p>Whale watching off the coast of Vancouver Island</p>
                    </li>
                </ul>

                <h1 class="highlights">Cultural Highlights</h1>
                <ul>
                    <li>
                        <p>Indigenous heritage sites and cultural centers</p>
                    </li>
                    <li>
                        <p>The vibrant arts scene in Vancouver</p>
                    </li>
                    <li>
                        <p>Historical sites in Victoria, including the Royal BC Museum</p>
                    </li>
                </ul>
            </div>

            <div class="card" id="alberta">
                <div class="heading">
                    <h1>
                        2. Alberta
                    </h1>
                    <div class="image"><img src="@/assets/alberta.jpg" alt="Alberta scenery"></div>
                </div>


                <h1>Introduction</h1>

                <p>
                    Alberta is home to some of Canada's most iconic natural landscapes, including the Rocky Mountains
                    and vast prairies. It's a province of dramatic contrasts and rich history.
                </p>

                <h1 >Key Attractions</h1>
                <ul>
                    <li>
                        <p><strong>Banff National Park</strong> A UNESCO World Heritage Site known for its stunning
                            mountain scenery, hot springs, and Lake Louise</p>
                    </li>
                    <li>
                        <p><strong>Jasper National Park</strong> Offers more rugged and less crowded alternatives to
                            Banff, with spectacular wildlife and hiking trails</p>
                    </li>
                    <li>
                        <p><strong>Calgary</strong> Famous for the Calgary Stampede, this city combines a cosmopolitan
                            vibe with a strong cowboy culture</p>
                    </li>
                    <li>
                        <p><strong>Edmonton</strong> Known for its massive West Edmonton Mall and vibrant arts
                            community</p>
                    </li>
                </ul>

                <h1 >Activities</h1>
                <ul>
                    <li>
                        <p>Exploring the Icefields Parkway, one of the most scenic drives in the world</p>
                    </li>
                    <li>
                        <p>Wildlife spotting, including bears, elk, and bighorn sheep</p>
                    </li>
                    <li>
                        <p>Skiing in the Rocky Mountains</p>
                    </li>
                    <li>
                        <p>Attending the Calgary Stampede</p>
                    </li>
                </ul>

                <h1 class="highlights">Cultural Highlights</h1>
                <ul>
                    <li>
                        <p>Indigenous history and cultural experiences</p>
                    </li>
                    <li>
                        <p>The Royal Tyrrell Museum of Paleontology in Drumheller</p>
                    </li>
                    <li>
                        <p>Edmonton’s arts festivals and theater scene</p>
                    </li>
                </ul>
            </div>

            <div class="card" id="sas">
                <div class="heading">
                    <h1>
                        3. Saskatchewan
                    </h1>
                    <div class="image"><img src="@/assets/saskatchewan.jpg" alt="Saskatchewan scenery"></div>
                </div>


                <h1>Introduction</h1>

                <p>
                    Saskatchewan, known as the "Land of Living Skies," offers vast open spaces, beautiful prairies, and
                    a rich agricultural heritage.
                </p>

                <h1 >Key Attractions</h1>
                <ul>
                    <li>
                        <p><strong>Regina</strong> The capital city, home to the Royal Saskatchewan Museum and the RCMP
                            Heritage Centre</p>
                    </li>
                    <li>
                        <p><strong>Saskatoon</strong> Known for its vibrant cultural scene and the scenic Meewasin
                            Valley</p>
                    </li>
                    <li>
                        <p><strong>Prince Albert National Park</strong> A mix of prairies and boreal forest, offering
                            excellent opportunities for wildlife viewing and outdoor activities</p>
                    </li>
                </ul>

                <h1 >Activities</h1>
                <ul>
                    <li>
                        <p>Exploring the Grasslands National Park</p>
                    </li>
                    <li>
                        <p>Fishing and boating in the province’s many lakes</p>
                    </li>
                    <li>
                        <p>Visiting historical sites like Batoche</p>
                    </li>
                </ul>

                <h1 class="highlights">Cultural Highlights</h1>
                <ul>
                    <li>
                        <p>Indigenous culture and history</p>
                    </li>
                    <li>
                        <p>Agricultural fairs and festivals</p>
                    </li>
                    <li>
                        <p>The thriving arts scene in Saskatoon and Regina</p>
                    </li>
                </ul>
            </div>

            <div class="card" id="manitoba">
                <div class="heading">
                    <h1>
                        4. Manitoba
                    </h1>
                    <div class="image"><img src="@/assets/manitoba.jpg" alt="Manitoba scenery"></div>
                </div>


                <h1>Introduction</h1>

                <p>
                    Manitoba is a province of diverse landscapes, from the prairies to the boreal forests and Arctic
                    tundra. It's also a cultural hotspot with a rich indigenous heritage.
                </p>

                <h1 >Key Attractions</h1>
                <ul>
                    <li>
                        <p><strong>Winnipeg</strong> The capital city, known for its cultural institutions like the
                            Canadian Museum for Human Rights and The Forks</p>
                    </li>
                    <li>
                        <p><strong>Churchill</strong> Famous for its polar bear and beluga whale watching
                            opportunities</p>
                    </li>
                    <li>
                        <p><strong>Riding Mountain National Park</strong> Offers a mix of forest, prairie, and lakes,
                            perfect for outdoor enthusiasts</p>
                    </li>
                </ul>

                <h1 >Activities</h1>
                <ul>
                    <li>
                        <p>Polar bear and beluga whale watching in Churchill</p>
                    </li>
                    <li>
                        <p>Exploring the cultural and historical sites in Winnipeg</p>
                    </li>
                    <li>
                        <p>Hiking and camping in Riding Mountain National Park</p>
                    </li>
                </ul>

                <h1 class="highlights">Cultural Highlights</h1>
                <ul>
                    <li>
                        <p>The Manitoba Museum and Winnipeg Art Gallery</p>
                    </li>
                    <li>
                        <p>Indigenous cultural experiences and festivals</p>
                    </li>
                    <li>
                        <p>Folklorama, an annual multicultural festival in Winnipeg</p>
                    </li>
                </ul>
            </div>

            <div class="card" id="ontario">
                <div class="heading">
                    <h1>
                        5. Ontario
                    </h1>
                    <div class="image"><img src="@/assets/ontario.jpg" alt="Ontario scenery"></div>
                </div>


                <h1>Introduction</h1>

                <p>
                    Ontario, Canada's most populous province, is home to the nation's capital, Ottawa, and the bustling
                    metropolis of Toronto. It offers a mix of urban excitement and natural beauty.
                </p>

                <h1 >Key Attractions</h1>
                <ul>
                    <li>
                        <p><strong>Toronto</strong> Known for the CN Tower, diverse neighborhoods, and cultural
                            institutions like the Royal Ontario Museum</p>
                    </li>
                    <li>
                        <p><strong>Ottawa</strong> The capital city, home to Parliament Hill, national museums, and the
                            Rideau Canal</p>
                    </li>
                    <li>
                        <p><strong>Niagara Falls</strong> One of the most famous natural attractions in the world</p>
                    </li>
                    <li>
                        <p><strong>Algonquin Provincial Park</strong> A vast wilderness area offering excellent hiking,
                            canoeing, and wildlife viewing</p>
                    </li>
                </ul>

                <h1 >Activities</h1>
                <ul>
                    <li>
                        <p>Exploring Toronto’s cultural and entertainment districts</p>
                    </li>
                    <li>
                        <p>Visiting the museums and historic sites in Ottawa</p>
                    </li>
                    <li>
                        <p>Taking a boat tour to the base of Niagara Falls</p>
                    </li>
                    <li>
                        <p>Canoeing and camping in Algonquin Park</p>
                    </li>
                </ul>

                <h1 class="highlights">Cultural Highlights</h1>
                <ul>
                    <li>
                        <p>The Toronto International Film Festival</p>
                    </li>
                    <li>
                        <p>The National Gallery of Canada in Ottawa</p>
                    </li>
                    <li>
                        <p>Indigenous heritage sites and cultural centers</p>
                    </li>
                </ul>
            </div>

            <div class="card" id="quebec">
                <div class="heading">
                    <h1>
                        6. Quebec
                    </h1>
                    <div class="image"><img src="@/assets/quebec.jpg" alt="Quebec scenery"></div>
                </div>


                <h1>Introduction</h1>

                <p>
                    Quebec, with its strong French heritage, offers a unique cultural experience in North America. It’s
                    known for its vibrant cities, historic sites, and natural beauty.
                </p>

                <h1 >Key Attractions</h1>
                <ul>
                    <li>
                        <p><strong>Montreal</strong> A cosmopolitan city known for its festivals, cuisine, and historic
                            Old Montreal</p>
                    </li>
                    <li>
                        <p><strong>Quebec City</strong> A UNESCO World Heritage Site with well preserved 17th century
                            architecture and the iconic Château Frontenac</p>
                    </li>
                    <li>
                        <p><strong>Gaspé Peninsula</strong> Known for its stunning coastal scenery and the Gaspésie
                            National Park</p>
                    </li>
                    <li>
                        <p><strong>Charlevoix</strong> A region of mountains and rivers, perfect for outdoor adventures
                            and scenic drives</p>
                    </li>
                </ul>

                <h1 >Activities</h1>
                <ul>
                    <li>
                        <p>Exploring the historic streets of Old Montreal and Quebec City</p>
                    </li>
                    <li>
                        <p>Hiking in the Laurentian Mountains</p>
                    </li>
                    <li>
                        <p>Whale watching in Tadoussac</p>
                    </li>
                    <li>
                        <p>Skiing in Mont Tremblant</p>
                    </li>
                </ul>

                <h1 class="highlights">Cultural Highlights</h1>
                <ul>
                    <li>
                        <p>The Montreal Jazz Festival and Quebec Winter Carnival</p>
                    </li>
                    <li>
                        <p>Indigenous culture and history</p>
                    </li>
                    <li>
                        <p>The rich culinary traditions, including poutine and maple syrup</p>
                    </li>
                </ul>
            </div>

            <div class="card" id="brunswick">
                <div class="heading">
                    <h1>
                        7. New Brunswick
                    </h1>
                    <div class="image"><img src="@/assets/brunswick.jpg" alt="New Brunswick scenery"></div>
                </div>


                <h1>Introduction</h1>

                <p>
                    New Brunswick, part of the Maritime Provinces, offers beautiful coastal scenery, charming small
                    towns, and a mix of French and English cultures.
                </p>

                <h1 >Key Attractions</h1>
                <ul>
                    <li>
                        <p><strong>Bay of Fundy</strong> Known for the highest tides in the world and opportunities for
                            whale watching</p>
                    </li>
                    <li>
                        <p><strong>Fredericton</strong> The capital city, home to historic sites and vibrant arts and
                            cultural scenes</p>
                    </li>
                    <li>
                        <p><strong>Saint John</strong> A historic port city with a bustling market and access to Fundy
                            National Park</p>
                    </li>
                </ul>

                <h1 >Activities</h1>
                <ul>
                    <li>
                        <p>Exploring the Hopewell Rocks and watching the tides</p>
                    </li>
                    <li>
                        <p>Hiking and camping in Fundy National Park</p>
                    </li>
                    <li>
                        <p>Visiting the historic sites and museums in Fredericton</p>
                    </li>
                    <li>
                        <p>Whale watching and coastal adventures</p>
                    </li>
                </ul>

                <h1 class="highlights">Cultural Highlights</h1>
                <ul>
                    <li>
                        <p>The Acadian culture and festivals</p>
                    </li>
                    <li>
                        <p>The New Brunswick Museum in Saint John</p>
                    </li>
                    <li>
                        <p>Indigenous cultural experiences</p>
                    </li>
                </ul>
            </div>

            <div class="card" id="scotia">
                <div class="heading">
                    <h1>
                        8. Nova Scotia
                    </h1>
                    <div class="image"><img src="@/assets/nova-scotia.jpg" alt="Nova Scotia scenery"></div>
                </div>


                <h1>Introduction</h1>

                <p>
                    Nova Scotia, with its picturesque coastline and maritime heritage, offers a charming and laid back
                    travel experience.
                </p>

                <h1 >Key Attractions</h1>
                <ul>
                    <li>
                        <p><strong>Halifax</strong> The capital city, known for its waterfront, historic sites, and
                            vibrant arts scene</p>
                    </li>
                    <li>
                        <p><strong>Cape Breton Island</strong> Famous for the Cabot Trail, a scenic drive offering
                            stunning coastal views</p>
                    </li>
                    <li>
                        <p><strong>Peggy’s Cove</strong> Home to one of the most photographed lighthouses in the world.
                        </p>
                    </li>
                    <li>
                        <p><strong>Lunenburg</strong> A UNESCO World Heritage site known for its colorful houses and
                            maritime history</p>
                    </li>
                </ul>

                <h1 >Activities</h1>
                <ul>
                    <li>
                        <p>Driving the scenic Cabot Trail</p>
                    </li>
                    <li>
                        <p>Exploring the historic waterfront in Halifax</p>
                    </li>
                    <li>
                        <p>Visiting the lighthouse at Peggy’s Cove</p>
                    </li>
                    <li>
                        <p>Whale watching and coastal hiking</p>
                    </li>
                </ul>

                <h1 class="highlights">Cultural Highlights</h1>
                <ul>
                    <li>
                        <p>The Celtic Colours International Festival on Cape Breton Island</p>
                    </li>
                    <li>
                        <p>The Maritime Museum of the Atlantic in Halifax</p>
                    </li>
                    <li>
                        <p>The rich Acadian culture</p>
                    </li>
                </ul>
            </div>

            <div class="card" id="edward">
                <div class="heading">
                    <h1>
                        9. Prince Edward Island
                    </h1>
                    <div class="image"><img src="@/assets/edward.avif" alt="Prince Edward Island scenery"></div>
                </div>


                <h1>Introduction</h1>

                <p>
                    Prince Edward Island (PEI), known for its red sand beaches and rolling countryside, is the smallest
                    province but offers big experiences.
                </p>

                <h1 >Key Attractions</h1>
                <ul>
                    <li>
                        <p><strong>Charlottetown</strong> The capital city, known as the birthplace of Confederation.
                        </p>
                    </li>
                    <li>
                        <p><strong>Cavendish</strong> Home to the famous Green Gables House, the setting for "Anne of
                            Green Gables."</p>
                    </li>
                    <li>
                        <p><strong>PEI National Park</strong> Offers beautiful beaches, dunes, and trails</p>
                    </li>
                </ul>

                <h1 >Activities</h1>
                <ul>
                    <li>
                        <p>Exploring the historic sites in Charlottetown</p>
                    </li>
                    <li>
                        <p>Visiting the Green Gables Heritage Place</p>
                    </li>
                    <li>
                        <p>Cycling the Confederation Trail</p>
                    </li>
                    <li>
                        <p>Enjoying the beaches and seafood</p>
                    </li>
                </ul>

                <h1 class="highlights">Cultural Highlights</h1>
                <ul>
                    <li>
                        <p>The Anne of Green Gables musical</p>
                    </li>
                    <li>
                        <p>The PEI International Shellfish Festival</p>
                    </li>
                    <li>
                        <p>The island’s rich literary and musical traditions</p>
                    </li>
                </ul>
            </div>

            <div class="card" id="labrador">
                <div class="heading">
                    <h1>
                        10 Newfoundland and Labrador
                    </h1>
                    <div class="image"><img src="@/assets/labrador.jpg" alt="Newfoundland and Labrador scenery"></div>
                </div>


                <h1>Introduction</h1>

                <p>
                    Newfoundland and Labrador, the easternmost province, offers rugged beauty and a rich maritime
                    heritage.
                </p>

                <h1 >Key Attractions</h1>
                <ul>
                    <li>
                        <p><strong>St. John’s</strong> The capital city, known for its colorful row houses and historic
                            sites</p>
                    </li>
                    <li>
                        <p><strong>Gros Morne National Park</strong> A UNESCO World Heritage site known for its
                            dramatic landscapes, including fjords, cliffs, and waterfalls</p>
                    </li>
                    <li>
                        <p><strong>L’Anse aux Meadows</strong> The site of an ancient Norse settlement, offering a
                            glimpse into North America's Viking history</p>
                    </li>
                    <li>
                        <p><strong>Signal Hill</strong> A historic site overlooking St. John’s, known for its panoramic
                            views and historical significance</p>
                    </li>
                </ul>

                <h1 >Activities</h1>
                <ul>
                    <li>
                        <p>Hiking in Gros Morne National Park</p>
                    </li>
                    <li>
                        <p>Exploring the Viking history at L’Anse aux Meadows</p>
                    </li>
                    <li>
                        <p>Whale watching and iceberg spotting off the coast</p>
                    </li>
                    <li>
                        <p>Visiting the historic sites in St. John’s</p>
                    </li>
                </ul>

                <h1 class="highlights">Cultural Highlights</h1>
                <ul>
                    <li>
                        <p>The Newfoundland and Labrador Folk Festival</p>
                    </li>
                    <li>
                        <p>The Rooms, a cultural facility in St. John’s</p>
                    </li>
                    <li>
                        <p>Traditional music and dance</p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.content{
    padding-bottom: 3rem;
}

.content .header{
    margin-top: 4rem;
}

.content .header h1{
    font-size: 2.3;
    letter-spacing: 2px;
    font-weight: 300;
}

.content .header p{
    margin: 3rem auto 0 auto;
    width: 55%;
}

.content .grid {
    width: 70%;
    margin: 4rem auto 2em auto;
}

.content .grid .card {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3em;
}

.content .grid .image {
    transition: all 0.2s;
    width: 80%;
    aspect-ratio: 3/2;
    margin: 3em auto 0 auto;
}

.content .grid .image:hover {
    transform: scale(0.9);
}

.content .grid .image img {
    border-radius: .7rem;
    object-fit: cover;
}

.content .grid .card h1 {
    font-size: 2.3rem;
    font-weight: 300;
    letter-spacing: 2px;
}

.content .grid .card ul {
    display: flex;
    flex-direction: column;
    gap: 1em;
    text-align: left;
    list-style: circle;
    width: 80%;
}

@media screen and (max-width: 1300px) {
    .content .grid {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        grid-auto-rows: 300px;
        gap: 1em;
    }
}

@media screen and (max-width: 600px) {
    .content .grid {
        display: grid;
        grid-template-columns: 1;
        grid-auto-rows: auto;
        gap: 1em;

    }

    .content .grid .text h1 {
        /* margin-top: 1rem; */
        font-size: 2.3rem;
    }

    .content .grid .text p {
        margin-top: 2rem;
        font-size: 1.2rem;
    }
}
</style>