<template>
    <div class="gray">
        <h1>{{$t('footer-h1')}}</h1>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>
.gray{
    background-color: rgb(255, 255, 255);
    color: #000000;
    border-top: 1px solid black;
    padding: 2em;
}
h1{
    font-size: 1rem;
    letter-spacing: 2px;
}
</style>